/**
 * <CoursesTile> Component
 */
import PropTypes from 'prop-types';
/** @jsx jsx this comment is required for Storybook to render */
import { css, jsx } from '@emotion/core';
import {
  breakpoints,
  colors,
  fontSizes,
  shadows,
  unitConverter as uc,
} from '../../../styles/base';
import { Heading3 } from '../../atoms/Headings/Headings';
import Card from '../../atoms/Card/Card';
import Text from '../../atoms/Text/Text';
import sanityImage from '../../../utils/sanityImage';
/*
 * import sanityImage from '../../../utils/sanityImage';
 * import Image from '../../atoms/Image/Image';
 */

const CoursesTile = ({
  className,
  courseTitle,
  provider,
  subject,
  thumbnailImage,
}) => {
  const formatProvider = provider.replace(/\s+/g, '').toLowerCase();
  const thumbNailDefaultImage = `'/contentCourseCatalog/${formatProvider}/thumbNail1.png'`;
  const providerLogoDefaultImage = `'/contentCourseCatalog/${formatProvider}/logo.png'`;

  const thumbnailImageToUse =
    thumbnailImage && thumbnailImage.asset
      ? sanityImage(thumbnailImage)
          .auto('format')
          .height(200)
          .format('jpg')
          .url()
      : thumbNailDefaultImage;

  const coursesTileCardCss = css`
    position: relative;
    max-width: ${uc('318px')};
    height: ${uc('380px')};
    margin: ${uc('0 0 50px 0')};
    overflow: hidden;

    @media (${breakpoints.tablet}) {
      margin: ${uc('0 auto 50px auto')};
    }

    @media (${breakpoints.mobile}) {
      margin: ${uc('0 auto 10px auto')};
    }
  `;

  const backgroundCss = css`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: ${uc('400px')};
    overflow: hidden;
    background-image: url(${thumbnailImageToUse});
    background-repeat: no-repeat;
    background-size: auto;
    clip-path: polygon(0 0, 100% 0, 100% 40.5%, 0 50%);
  `;

  const imageCss = css`
    position: absolute;
    top: 30%;
    right: 15%;
    width: ${uc('100px')};
    height: ${uc('100px')};
    background-color: ${colors.white};
    background-image: url(${providerLogoDefaultImage});
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border-radius: 50%;
    box-shadow: ${shadows.large};
  `;

  const textContainerCss = css`
    display: flex;
    flex-direction: column;
    margin: ${uc('225px 20px 0')};
  `;

  const headingCss = css`
    display: -webkit-box;
    overflow: hidden;
    font-size: ${fontSizes.sixteen} !important;
    text-overflow: ellipsis;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  `;

  return (
    <Card css={coursesTileCardCss} className={className}>
      <div className="tileBkg" css={backgroundCss} />
      <div css={imageCss} />
      <div css={textContainerCss}>
        <Heading3
          css={headingCss}
          fontSize={fontSizes.sixteen}
          color={colors.darkGray.five}
        >
          {courseTitle}
        </Heading3>
        <Text color={colors.lightGray.five}>{subject}</Text>
      </div>
    </Card>
  );
};

CoursesTile.propTypes = {
  className: PropTypes.string,
  courseTitle: PropTypes.string,
  subject: PropTypes.string,
  provider: PropTypes.string,
  thumbnailImage: PropTypes.shape([]),
};

CoursesTile.defaultProps = {
  className: '',
  courseTitle: null,
  subject: null,
  provider: null,
  thumbnailImage: null,
};

export default CoursesTile;
